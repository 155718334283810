<script setup  lang="ts">
import useSubHeaderState from './useSubHeaderState';

const {
    breadcrumbs,
} = useSubHeaderState();
</script>

<template>
    <div class="flex h-10 items-center border-b border-b-shade-1 bg-background-1 px-container-gap">
        <div class="flex items-center space-x-2 typo-meta-s-medium">
            <template
                v-for="(breadcrumb, index) in breadcrumbs"
                :key="breadcrumb.label"
            >
                <span v-if="index > 0">/</span>
                <button

                    class="flex items-center"
                    :class="!!breadcrumb.callback ? 'cursor-pointer underline' : 'cursor-default'"
                    @click="breadcrumb.callback"
                >
                    <Icon v-if="index === 0" name="tot:caret-left" class="mr-2 block size-4" />
                    <span>{{ breadcrumb.label }}</span>
                </button>
            </template>
        </div>
    </div>
</template>
